<template>
  <div
    class="feature-bento-box h-full rounded-lg overflow-hidden cursor-pointer"
    @click="onClick"
  >
    <NuxtImg v-bind="image" class="inline-block rounded rounded-lg w-100" />

    <div class="">
      <MyLink v-if="link" :link="link" class="no-underline">
        <component
          :is="headlineTag"
          class="my-4 h3"
          :class="{ 'mb-0': !teaser }"
        >
          {{ headline }}
        </component>
      </MyLink>
      <component
        :is="headlineTag"
        v-else
        class="h3"
        :class="{ 'mb-0': !teaser }"
      >
        {{ headline }}
      </component>
      <div
        v-if="teaser"
        class="feature-bento-box-summary"
        v-html="teaser"
      ></div>
      <slot />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  image: {
    type: Object,
    required: true,
  },
  headline: {
    type: String,
    required: true,
  },
  headlineTag: {
    type: String,
    default: "h3",
  },
  teaser: {
    type: String,
    required: true,
  },
  link: {
    type: Object,
    required: true,
  },
});

const utils = useUtils();

const onClick = () => {
  if (utils.getUrlFromLink(props.link)) {
    utils.goto(`${utils.getUrlFromLink(props.link)}`);
  }
};
</script>

<style scoped>
.card-wrapper > * {
  color: #000;
}
.h3 {
  color: inherit;
}
</style>
